import { FC } from 'react';
import { RacingInfos } from '../../types';
import './raceinfos.scss';
import { GaugeView } from './gauge';

/* -------------------------------------------------------------------------------- *\
|                               RaceInfosProps
\* -------------------------------------------------------------------------------- */
interface RaceInfosProps
{
	infos: RacingInfos | undefined,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               GaugeView
\* -------------------------------------------------------------------------------- */
export const RaceInfosView : FC<RaceInfosProps> = ({infos}) =>
{
    const active: boolean = infos != null;
    const started: boolean = infos ? infos.started : false;
    const percent: number = (infos && infos.distance && infos.position) ? (100 * infos.position / infos.distance) : 0;
    const hsgpscolor: string = infos && infos.hsgpscount > 1 ? "alarm" : "neutral";
    const noposcolor: string = started && infos && infos.hsracecount > 1 ? "alarm" : "neutral";

    return (
        <div className='raceinfos'>
        {
            active &&
            (
                <div className='raceinfos__line'>
                    <div className='raceinfos__case'>
                        <div className='raceinfos__title'>data/s</div>
                        <div className='raceinfos__content'>{infos?.dps}</div>
                    </div>
                    <div className='raceinfos__case'>
                        <div className='raceinfos__title'>runners</div>
                        <div className='raceinfos__content'>{infos?.trackers}</div>
                    </div>
                    <div className='raceinfos__case' style={{'background': `var(--state-${hsgpscolor})`}}>
                        <div className='raceinfos__title'>no_gps</div>
                        <div className='raceinfos__content'>{infos?.hsgpscount}</div>
                    </div>
                    <div className='raceinfos__case'>
                        <div className='raceinfos__title'>started</div>
                        <div className='raceinfos__content'>{infos?.started ? "true" : "false"}</div>
                    </div>
                    {
                        started &&
                        (
                            <div className='raceinfos__case' style={{'background': `var(--state-${noposcolor})`}}>
                                <div className='raceinfos__title'>no_pos</div>
                                <div className='raceinfos__content'>{infos?.hsracecount}</div>
                            </div>
                        )
                    }
                    {
                        started &&
                        (
                            <div className='raceinfos__case'>
                                <div className='raceinfos__title'>position</div>
                                <div className='raceinfos__content'>{infos?.position.toFixed() + "m"}</div>
                            </div>
                        )
                    }
                    {
                        started &&
                        (
                            <div className='raceinfos__gauge'>
                                <div className='raceinfos__title raceinfos__gauge__title'>{'track ' + (infos ? infos.distance.toFixed() + "m" : '')}</div>
                                <GaugeView percent={percent}/>
                            </div>
                        )
                    }
                </div>
            )
        }
        {
            active &&
            (
                <div className='raceinfos__chrono'>
                {
                    infos?.competitors.map((c, i) =>
                    {
                        const isdp: boolean = c.status === "DP";
                        const qmods: number[] = Array(10).fill(0).concat(c.qmods ? c.qmods : []).slice(-10);
                        return (
                            <div className='raceinfos__chrono__line' key={'comp_' + infos?.sic + '_' + i}>
                                <div className='raceinfos__chrono__line__number'>{c.number}</div>
                                {
                                    !isdp &&
                                    (
                                        <div className='raceinfos__chrono__line__status'>{c.status}</div>
                                    )
                                }
                                {
                                    isdp &&
                                    (
                                        <div className='raceinfos__chrono__line__qmods'>
                                            <div className='raceinfos__chrono__line__latency'>{c.latency ? c.latency + "ms" : ''}</div>
                                        {
                                            qmods.map((q, qi) =>
                                            {
                                                return (
                                                    <div className={'raceinfos__chrono__line__qmod raceinfos__chrono__line__qcolor' + q} key={'qmod_' + infos?.sic + '_' + qi}></div>
                                                )
                                            })
                                        }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    })
                }
                </div>
            )
        }
        </div>
    );
}
